import {
  CalendarToday,
  CalendarViewDay,
  Close,
  Description,
  Email,
  FileCopy,
  Fingerprint,
  HourglassEmptyRounded,
  Key,
  OpenInNew,
  Output,
  Phone,
  Search,
  Visibility,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  TextField,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import toastr from "toastr";
import _ from "underscore";
import Loader from "../common/loader";
import {
  getBlockChainUrl,
  getTransactionUrl,
  IsNullOrEmpty,
  pageSize,
  paginate,
} from "../helpers/utility";
import {
  fetchPresaleRequest,
  updatePresaleRequest,
} from "../store/presale/presale.action";
import { getPresales } from "../store/presale/presale.selector";

function Presale() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("All");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [datum, setDatum] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(fetchPresaleRequest());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (sta) => {
    setAnchorEl(null);
  };

  const { presales, isLoading } = useSelector((state) => {
    return {
      presales: getPresales(state),
      isLoading: state.PresaleReducer.isLoading,
    };
  });

  useEffect(() => {
    if (selectedItem) {
      let z = _.find(presales, (x) => x.id === selectedItem);
      setDatum(z);
    } else {
      setDatum({});
    }
  }, [selectedItem]);

  let filteredList = presales;
  if (!filteredList) {
    filteredList = [];
  }

  if (status !== "All") {
    filteredList = filteredList.filter((x) => x.isActive === status);
  }

  if (!IsNullOrEmpty(searchText)) {
    filteredList = filteredList.filter(
      (x) =>
        x.id.toLowerCase().indexOf(searchText.toLowerCase()) >
          -1 ||
        x.counterAddress.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.address.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.currency.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );
  }

  if (!IsNullOrEmpty(filter)) {
    let startTime = moment().startOf("day");
    let endTime = moment().endOf("day");
    switch (filter) {
      case "Today":
        filteredList = filteredList.filter((x) => {
          return moment.unix(x.createdOn.seconds).isBetween(startTime, endTime);
        });
        break;
      case "Week":
        startTime = moment().startOf("weeks");
        endTime = moment().endOf("weeks");
        filteredList = filteredList.filter((x) => {
          return moment.unix(x.createdOn.seconds).isBetween(startTime, endTime);
        });
        break;
      case "Month":
        startTime = moment().startOf("month");
        endTime = moment().endOf("month");
        filteredList = filteredList.filter((x) => {
          return moment.unix(x.createdOn.seconds).isBetween(startTime, endTime);
        });
        break;
      case "Year":
        startTime = moment().startOf("year");
        endTime = moment().endOf("year");
        filteredList = filteredList.filter((x) => {
          return moment.unix(x.createdOn.seconds).isBetween(startTime, endTime);
        });
        break;
    }
  }

  filteredList = _.sortBy(filteredList, "createdOn").reverse();
  let totalPages = Math.ceil(filteredList.length / pageSize);
  filteredList = paginate(filteredList, pageSize, page);

  return (
    <div>
      {isLoading && <Loader />}
      <Card className="mt-1">
        <CardHeader
          title="Presale Wallets"
          subheader={"List of Presale Wallets"}
          avatar={<Key />}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                className="rounded-lg"
                fullWidth
                style={{ marginTop: 0 }}
                margin="dense"
                size="small"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Select
                native
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                value={filter}
                onChange={(e) => [setFilter(e.target.value)]}
              >
                <option value="">All Time</option>
                <option value="Today">Today</option>
                <option value="Week">This Week</option>
                <option value="Month">This Month</option>
                <option value="Year">This Year</option>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                {filteredList.map((dep) => {
                  return (
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Card variant="outlined">
                        <CardHeader
                          classes={{
                            root: "p-1",
                            action: "px-3 py-2",
                          }}
                          title={`${
                            dep.tokens * Math.pow(10, dep.token.tokenDecimals)
                          } ${dep.token.tokenSymbol}`}
                          subheader={moment
                            .unix(dep.createdOn.seconds)
                            .format("lll")}
                          action={
                            <Visibility
                              color="secondary"
                              onClick={() => setSelectedItem(dep.id)}
                            />
                          }
                        />
                        <Divider />
                        <CardContent className="p-0">
                          <table className="table-striped text-medium border-none">
                            <tr>
                              <td>Coins Spend</td>
                              <td>
                                {dep.amount}{" "}
                                {dep.contractAddress ? "USDT" : "BNB"}
                              </td>
                            </tr>
                            <tr>
                              <td>Token Price</td>
                              <td>{dep.tokenPrice} USDT / Token</td>
                            </tr>
                            <tr>
                              <td>BNB Price</td>
                              <td>{dep.coinPrice} USDT / BNB</td>
                            </tr>
                          </table>
                        </CardContent>
                        <CardActions>
                          <Button
                            variant={
                              dep.transactionHash ? "contained" : "outlined"
                            }
                            fullWidth
                            color={
                              dep.transactionHash ? "primary" : "secondary"
                            }
                            onClick={() => {
                              if (!dep.transactionHash) {
                                Swal.fire({
                                  title: "Send Coins",
                                  text: "Are you sure want to send coins",
                                  icon: "question",
                                  showCancelButton: true,
                                }).then((s) => {
                                  if (s.isConfirmed) {
                                    dispatch(updatePresaleRequest(dep.id));
                                  }
                                });
                              }
                            }}
                            size="small"
                          >
                            {dep.transactionHash ? "Transfered" : "Pending"}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid xs={12} sm={12}>
              {filteredList.length > 0 ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className="my-2"
                >
                  <Pagination
                    shape="rounded"
                    variant="outlined"
                    count={totalPages}
                    page={page}
                    color="primary"
                    onChange={(e, pg) => {
                      setPage(pg);
                    }}
                  />
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  height="100%"
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className="my-2"
                >
                  <HourglassEmptyRounded
                    color="secondary"
                    fontSize="large"
                    style={{ fontSize: 64 }}
                  />
                  <p>No data available</p>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {datum.id && (
        <Dialog
          maxWidth="md"
          fullWidth
          open={!IsNullOrEmpty(selectedItem) && datum.id}
        >
          <DialogTitle>
            Presale Information
            <Close
              className="float-right mt-2"
              onClick={() => {
                setSelectedItem("");
              }}
            />
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Transaction ID"
                      primary={datum.id}
                    />
                    <ListItemSecondaryAction>
                      <a
                        target="_blank"
                        href={getTransactionUrl(datum.currency) + datum.id}
                      >
                        <OpenInNew color="primary" />
                      </a>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Admin Address"
                      primary={datum.address}
                    />
                    <ListItemSecondaryAction>
                      <a
                        target="_blank"
                        href={getBlockChainUrl(datum.currency) + datum.address}
                      >
                        <OpenInNew color="primary" />
                      </a>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="From Address"
                      primary={datum.counterAddress}
                    />
                    <ListItemSecondaryAction>
                      <a
                        target="_blank"
                        href={
                          getBlockChainUrl(datum.currency) +
                          datum.counterAddress
                        }
                      >
                        <OpenInNew color="primary" />
                      </a>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Email />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Spent Amount"
                      primary={`${datum.amount}${" "}
                                ${datum.contractAddress ? "USDT" : "BNB"}`}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Phone />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Tokens Received"
                      primary={
                        datum.tokens * Math.pow(10, datum.token.tokenDecimals) +
                        " " +
                        datum.token.tokenSymbol
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              {datum.contractAddress && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <List disablePadding>
                    <ListItem disableGutters>
                      <ListItemAvatar>
                        <Avatar>
                          <Description />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        secondary="Spend Token Address"
                        primary={datum.contractAddress}
                        primaryTypographyProps={{
                          className: "overflow-text",
                        }}
                      />
                      <ListItemSecondaryAction>
                        <a
                          target="_blank"
                          href={
                            getBlockChainUrl(datum.currency) +
                            datum.contractAddress
                          }
                        >
                          <OpenInNew color="primary" />
                        </a>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Output />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Block Number"
                      primary={datum.blockNumber}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Output />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Status"
                      primary={
                        datum.transactionHash ? "Transferred" : "Pending"
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarToday />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Created On"
                      primary={moment
                        .unix(datum.createdOn.seconds)
                        .format("lll")}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              moment.unix(datum.createdOn.seconds).format("lll")
                            );
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarViewDay />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Last Modified On"
                      primary={moment
                        .unix(datum.updatedOn.seconds)
                        .format("lll")}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              moment.unix(datum.updatedOn.seconds).format("lll")
                            );
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default Presale;
