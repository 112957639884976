import axios from "axios";
import { signOut } from "firebase/auth";
import _ from "underscore";
import { getAccessToken } from "../store/auth/auth.selector";
import store from "../store/store";
import { defaultAuth } from "./firebase/config";
export const COIN_PRICE_API = "https://api.alternative.me/v1/ticker/";
export const CRYPTO_API_KEY = "454d652cf0d7e434f306b4b7869b0332b4d65e9e";
export const INFURA_MAINNET = "7f52275666a84f0fbd2d5a83163f3fa9";
export const INFURA_ROPSTEN = "7f52275666a84f0fbd2d5a83163f3fa9";
export const BNB_API_KEY = "ckey_37b41205231d44b6954f09ac260";
export const MODE_TYPE = process.env.REACT_APP_MODE_TYPE;
export const pageSize = 18;

var xrpImg = process.env.PUBLIC_URL + "images/XRP.png";
var btcImg = process.env.PUBLIC_URL + "images/BTC.png";
var bchImg = process.env.PUBLIC_URL + "images/BCH.png";
var trxImg = process.env.PUBLIC_URL + "images/TRX.png";
var ltcImg = process.env.PUBLIC_URL + "images/LTC.png";
var ethImg = process.env.PUBLIC_URL + "images/ETH.png";
var bnbImg = process.env.PUBLIC_URL + "images/BNB.png";
var usdtImg = process.env.PUBLIC_URL + "images/TUSDT.png";
var busdImg = process.env.PUBLIC_URL + "images/BUSDT.png";

export function getLogo(type) {
  switch (type.toLowerCase()) {
    case "trx":
      return {
        logo: trxImg,
        name: "Tron",
      };
    case "btc":
      return {
        logo: btcImg,
        name: "Bit Coin",
      };
    case "bch":
      return {
        logo: bchImg,
        name: "Bit Coin Cash",
      };
    case "xrp":
      return {
        logo: xrpImg,
        name: "Ripple",
      };
    case "ltc":
      return {
        logo: ltcImg,
        name: "Lite Coin",
      };
    case "eth":
      return {
        logo: ethImg,
        name: "Ethereum",
      };
    case "bnb":
      return {
        logo: bnbImg,
        name: "Binance",
      };
    case "usdt":
      return {
        logo: usdtImg,
        name: "Tether",
      };
    case "busd":
      return {
        logo: busdImg,
        name: "BUSD",
      };
    default:
      return {
        logo: trxImg,
        name: "Tron",
      };
  }
}

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export function getHeaderDetail(headerInfo = {}) {
  const state = store.getState();
  const token = getAccessToken(state);
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE",
    Authorization: "Bearer " + token,
  };
  headers = { ...headers, ...headerInfo };

  return headers;
}

export function IsNullOrEmpty(name) {
  return name === "" || name === undefined || name === null;
}

export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !IsNullOrEmpty(str) && !!pattern.test(str);
}

export function validEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !IsNullOrEmpty(email) && re.test(String(email).toLowerCase());
}

export function validPhone(phone) {
  var regmm = "^([0|+[0-9]{1,5})?([7-9][0-9]{9})$";
  var regmob = new RegExp(regmm);
  return regmob.test(phone);
}

export function validPassword(password) {
  const pa = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.])[A-Za-z\d$@$!%*?&.]{8,}/
  );
  return !IsNullOrEmpty(password) && pa.test(password);
}

export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.name] =
      item.type === "FileDrop" || item.type === "Chip" ? [] : item.defaultValue;
    return obj;
  }, {});

export const replaceLocalSrcTags = (input, localSrc, src) => {
  return input.replace(localSrc, src);
};

export function clearToken() {
  sessionStorage.removeItem("persist:bitazure");
  signOut(defaultAuth)
    .then((z) => {})
    .finally(() => {
      sessionStorage.removeItem("persist:bitazure");
      document.location.href = process.env.PUBLIC_URL;
    });
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : r & (0x3 | 0x8);
    return v.toString(16);
  });
}

export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

export function getCoinAddressUrl(coin, address) {
  let explorerUrl = "";
  switch (coin.toLowerCase()) {
    case "trx":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://api.shasta.trongrid.io/v1/accounts/" + address
          : "https://api.trongrid.io/v1/accounts/" + address;
      break;
    case "btc":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://chain.so/api/v2/get_address_balance/BTCTEST/" + address
          : "https://chain.so/api/v2/get_address_balance/BTC/" + address;
      break;
    case "bch":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://rest.cryptoapis.io/v2/blockchain-data/bitcoin-cash/testnet/addresses/" +
            address
          : "https://api.blockcypher.com/v1/bch/main/addrs/" + address;
      break;
    case "ltc":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://testnet.litecore.io/api/addr/" + address
          : "https://api.blockcypher.com/v1/ltc/main/addrs/" + address;
      break;
    case "eth":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://ropsten.infura.io/v3/" + INFURA_ROPSTEN
          : "https://infura.io/v3/" + INFURA_MAINNET;
      break;
    case "bnb":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://api.covalenthq.com/v1/97/address/" +
            address +
            "/balances_v2/?key=" +
            BNB_API_KEY
          : "https://api.covalenthq.com/v1/56/address/" +
            address +
            "/balances_v2/?key=" +
            BNB_API_KEY;
      break;
    case "xrp":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://testnet.xrpl.org/api/v1/account_state/" + address
          : "https://xrpl.org/api/v1/account_state/" + address;
      break;
    default:
      break;
  }

  return explorerUrl;
}

export function getCoinPriceUrl(coin) {
  return (
    "https://api.binance.com/api/v1/klines?symbol=" +
    coin.toUpperCase() +
    "USDT&interval=1d&limit=7"
  );
}

export function getTransactionDetailUrl(coin) {
  let url = "";
  switch (coin.toLowerCase()) {
    case "btc":
      if (MODE_TYPE === "test") {
        url = "https://chain.so/api/v2/get_tx/BTCTEST/";
      } else {
        url = "https://chain.so/api/v2/get_tx/BTC";
      }
      break;
    default:
      break;
  }

  return url;
}

export function getTransactionUrl(coin) {
  let explorerUrl = "";
  switch (coin.toLowerCase()) {
    case "btc":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://www.blockchain.com/btc-testnet/tx/"
          : "https://www.blockchain.com/btc/tx/";
      break;
    case "bch":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://www.blockchain.com/bch-testnet/tx/"
          : "https://www.blockchain.com/bch/tx/";
      break;
    case "ltc":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://chain.so/tx/LTCTEST/tx/"
          : "https://chain.so/tx/LTC/";
      break;
    case "trx":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://shasta.tronscan.org/#/transaction/"
          : "https://tronscan.org/#/transaction/";
      break;
    case "eth":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://ropsten.etherscan.io/tx/"
          : "https://etherscan.io/tx/";
      break;
    case "bnb":
    case "bsc":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://testnet.bscscan.com/tx/"
          : "https://bscscan.com/tx/";
      break;
    case "xrp":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://testnet.xrpl.org/transactions/"
          : "https://livenet.xrpl.org/transactions/";
      break;
    default:
      break;
  }

  return explorerUrl;
}

export function getBlockChainUrl(coin) {
  let explorerUrl = "";
  switch (coin.toLowerCase()) {
    case "btc":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://www.blockchain.com/btc-testnet/address/"
          : "https://www.blockchain.com/btc/address/";
      break;
    case "bch":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://www.blockchain.com/bch-testnet/address/"
          : "https://www.blockchain.com/bch/address/";
      break;
    case "ltc":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://chain.so/address/LTCTEST/"
          : "https://chain.so/address/LTC/";
      break;
    case "trx":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://shasta.tronscan.org/#/address/"
          : "https://tronscan.org/#/address/";
      break;
    case "eth":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://ropsten.etherscan.io/address/"
          : "https://etherscan.io/address/";
      break;
    case "bnb":
    case "bsc":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://testnet.bscscan.com/address/"
          : "https://bscscan.com/address/";
      break;
    case "xrp":
      explorerUrl =
        MODE_TYPE === "test"
          ? "https://testnet.xrpl.org/accounts/"
          : "https://livenet.xrpl.org/accounts/";
      break;
    default:
      break;
  }

  return explorerUrl;
}

export function paginate(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export async function getCoinPrice() {
  const coinPrices = await axios({
    url: "https://api.alternative.me/v1/ticker/",
    method: "GET",
    timeout: 8000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (coinPrices.status == 200) {
    let res = coinPrices
      .filter((s) => {
        return (
          s.symbol === "BTC" ||
          s.symbol === "ETH" ||
          s.symbol === "XRP" ||
          s.symbol === "BNB" ||
          s.symbol === "USDT" ||
          s.symbol === "TRX" ||
          s.symbol === "LTC"
        );
      })
      .map((s) => {
        return {
          coin: s.symbol,
          usd: s.price_usd,
        };
      });

    return res;
  } else {
    console.log("Error in getting price");
    return [
      {
        coin: "BTC",
        usd: 0,
      },
      {
        coin: "ETH",
        usd: 0,
      },
      {
        coin: "XRP",
        usd: 0,
      },
      {
        coin: "BNB",
        usd: 0,
      },
      {
        coin: "USDT",
        usd: 0,
      },
      {
        coin: "TRX",
        usd: 0,
      },
      {
        coin: "LTC",
        usd: 0,
      },
    ];
  }
}

export function calculateData(prices, coinBalance, ignore) {
  let data = [];
  if (prices.length > 0) {
    _.forEach(prices, (p) => {
      let total = 0;
      if (p.coin === "BTC") {
        if (parseFloat(coinBalance[p.coin]) > 0 || ignore) {
          total = parseFloat(
            (parseFloat(coinBalance[p.coin] || 0) * parseFloat(p.usd)).toFixed(
              3
            )
          );
          data.push({
            coin: "BTC",
            val: total,
            price: parseFloat(p.usd).toFixed(3),
          });
        }
      } else if (p.coin === "BNB") {
        if (parseFloat(coinBalance[p.coin]) > 0 || ignore) {
          total = parseFloat(
            (parseFloat(coinBalance[p.coin] || 0) * parseFloat(p.usd)).toFixed(
              3
            )
          );
          data.push({
            coin: "BNB",
            val: total,
            price: parseFloat(p.usd).toFixed(3),
          });
        }
      } else if (p.coin === "ETH") {
        if (parseFloat(coinBalance[p.coin]) > 0 || ignore) {
          total = parseFloat(
            (parseFloat(coinBalance[p.coin] || 0) * parseFloat(p.usd)).toFixed(
              3
            )
          );
          data.push({
            coin: "ETH",
            val: total,
            price: parseFloat(p.usd).toFixed(3),
          });
        }
      } else if (p.coin === "LTC") {
        if (parseFloat(coinBalance[p.coin]) > 0 || ignore) {
          total = parseFloat(
            (parseFloat(coinBalance[p.coin] || 0) * parseFloat(p.usd)).toFixed(
              3
            )
          );
          data.push({
            coin: "LTC",
            val: total,
            price: parseFloat(p.usd).toFixed(3),
          });
        }
      } else if (p.coin === "XRP") {
        if (parseFloat(coinBalance[p.coin]) > 0 || ignore) {
          total = parseFloat(
            (parseFloat(coinBalance[p.coin] || 0) * parseFloat(p.usd)).toFixed(
              3
            )
          );
          data.push({
            coin: "XRP",
            val: total,
            price: parseFloat(p.usd).toFixed(3),
          });
        }
      } else if (p.coin === "USDT") {
        if (parseFloat(coinBalance["USDT"]) > 0 || ignore) {
          total = parseFloat(
            (parseFloat(coinBalance["USDT"] || 0) * parseFloat(p.usd)).toFixed(
              3
            )
          );
          data.push({
            coin: "USDT",
            val: total,
            price: parseFloat(p.usd).toFixed(3),
          });
        }
        if (parseFloat(coinBalance["BUSD"]) > 0 || ignore) {
          total = parseFloat(
            (parseFloat(coinBalance["BUSD"] || 0) * parseFloat(p.usd)).toFixed(
              3
            )
          );
          data.push({
            coin: "BUSD",
            val: total,
            price: parseFloat(p.usd).toFixed(3),
          });
        }
      } else if (p.coin === "TRX") {
        if (parseFloat(coinBalance[p.coin]) > 0 || ignore) {
          total = parseFloat(
            (parseFloat(coinBalance[p.coin] || 0) * parseFloat(p.usd)).toFixed(
              3
            )
          );
          data.push({
            coin: "TRX",
            val: total,
            price: parseFloat(p.usd).toFixed(3),
          });
        }
      }
    });
  }
  return data;
}
