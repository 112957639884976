import { UserActionTypes, userInitialState } from "./user.model";

const reducer = (state = userInitialState, action) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_KEY_REQUEST:
    case UserActionTypes.UPDATE_SETTINGS_REQUEST:
    case UserActionTypes.VERIFY_PIN_REQUEST:
    case UserActionTypes.UPDATE_USER_REQUEST:
    case UserActionTypes.GET_STATUS_REQUEST:
    case UserActionTypes.UPDATE_TOKEN_REQUEST:
    case UserActionTypes.GET_TOKEN_BAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UserActionTypes.UPDATE_KEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          accessKey: action.payload,
        },
      };

    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case UserActionTypes.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case UserActionTypes.VERIFY_PIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case UserActionTypes.GET_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stats: action.payload,
      };

    case UserActionTypes.UPDATE_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          tokens: action.payload,
        },
      };

    case UserActionTypes.GET_TOKEN_BAL_SUCCESS:
      let tokens =
        state.user &&
        state.user.tokens &&
        state.user.tokens.map((t) => {
          if (t.address === action.payload.address) {
            if (t.decimals === "18") {
              t.balance = action.payload.balance;
            } else {
              t.balance =
                parseFloat(action.payload.balance) *
                Math.pow(10, parseFloat(t.decimals));
            }
          }
          return t;
        });
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          tokens: tokens,
        },
      };

    case UserActionTypes.FETCH_BALANCE_REQUEST: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case UserActionTypes.FETCH_BALANCE_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        coinBalance: action.payload,
      });
    }

    case UserActionTypes.UPDATE_KEY_FAILURE:
    case UserActionTypes.UPDATE_SETTINGS_FAILURE:
    case UserActionTypes.VERIFY_PIN_FAILURE:
    case UserActionTypes.UPDATE_USER_FAILURE:
    case UserActionTypes.UPDATE_TOKEN_FAILURE:
    case UserActionTypes.GET_STATUS_FAILURE:
    case UserActionTypes.GET_TOKEN_BAL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export { reducer as UserReducer };

