import {
  collection,
  getDocs,
  limitToLast,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import Swal from "sweetalert2";
import { defaultDB } from "../../helpers/firebase/config";
import HttpHelper from "../../helpers/httpHelper";
import { getPresaleUrl } from "./presale.uri";

class PresaleAPI {
  static async GetPresales(uid) {
    const limit = 1000;
    let collectionRef = collection(defaultDB, "presales");
    const myQuery = query(
      collectionRef,
      where("tenantId", "==", uid),
      orderBy("createdOn", "desc"),
      limitToLast(limit)
    );
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });
  }

  static async UpdatePresales(id) {
    let url = getPresaleUrl(id);
    return HttpHelper.httpRequest(url, "PATCH", {});
  }
}

export default PresaleAPI;
