import { signInWithPopup } from "firebase/auth";
import { call, put, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import { defaultAuth, defaultProvider } from "../../helpers/firebase/config";
import UserAPI from "../user/user.api";
import * as AuthActions from "./auth.action";
import { AuthActionTypes } from "./auth.model";

export function* handleLogin(action) {
  let response = null;
  try {
    response = yield call(signInWithPopup, defaultAuth, defaultProvider);
    // response = yield call(
    //   signInWithEmailAndPassword,
    //   defaultAuth,
    //   action.payload,
    //   "Password@123"
    // );
    if (response.user.email === action.payload) {
      const response1 = yield call(UserAPI.CheckEmail, response.user.email);
      if (response1.isValid) {
        yield put(AuthActions.loginUserSuccess(response));
      } else {
        defaultAuth.currentUser.delete();
        Swal.fire({
          title: "Access Denied",
          text:
            response.user.email + " is not valid email to login to the system",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Access Denied",
        text: "Please login from your registered email ",
        icon: "error",
      });
    }
  } catch (e) {
    Swal.fire({
      title: "Login Failure",
      text: "Email not Exists, Please try again with different email",
      icon: "error",
    });
    yield put(AuthActions.loginUserFailure(e));
  }
}

export function* AuthSaga() {
  yield takeEvery(AuthActionTypes.LOGIN_USER_REQUEST, handleLogin);
}
