import { call, put, select, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import { getUserUID } from "../auth/auth.selector";
import * as PresaleActions from "./presale.action";
import PresaleAPI from "./presale.api";
import { PresaleActionTypes } from "./presale.model";

export function* handleFetchPresale(action) {
  try {
    const user = yield select(getUserUID);
    const response = yield call(PresaleAPI.GetPresales, user);
    yield put(PresaleActions.fetchPresaleSuccess(response));
  } catch (e) {
    yield put(PresaleActions.fetchPresaleFailure(e));
  }
}
export function* handleUpdatePresale(action) {
  try {
    const response = yield call(PresaleAPI.UpdatePresales, action.payload);
    yield put(PresaleActions.updatePresaleSuccess(response));
    Swal.fire({
      text: "Coins transferred successfully",
      title: "Coins Sent",
      icon: "success",
    });
  } catch (e) {
    yield put(PresaleActions.updatePresaleFailure(e));
  }
}
export function* PresaleSaga() {
  yield takeEvery(PresaleActionTypes.FETCH_PRESALE_REQUEST, handleFetchPresale);
  yield takeEvery(
    PresaleActionTypes.UPDATE_PRESALE_REQUEST,
    handleUpdatePresale
  );
}
