import { Google, Lock } from '@mui/icons-material';
import { Box, Button, Container, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from '../common/loader';
import { IsNullOrEmpty, validEmail } from '../helpers/utility';
import { loginUserRequest } from '../store/auth/auth.action';
import { getAccessToken } from '../store/auth/auth.selector';
import UserAPI from '../store/user/user.api';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isValid, setValid] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const signin = () => {
    dispatch(loginUserRequest(email));
  }

  const handleCheck = () => {
    setLoading(true);
    UserAPI.CheckEmail(email).then((s) => {
      if (s.disabled) {
        Swal.fire({
          title: "Access Denied",
          text: "Admin disabled your account.Please contact admin",
          icon: "error"
        })
      }
      else {
        setValid(s.isValid);
        setEmailVerified(s.emailVerified);
        setDisabled(s.disabled);
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const { accessToken, isLoading } = useSelector((state) => {
    return {
      accessToken: getAccessToken(state),
      isLoading: state.AuthReducer.isLoading
    }
  });

  useEffect(() => {
    if (!IsNullOrEmpty(accessToken)) {
      navigate("/");
    }
  }, [accessToken, navigate])

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100vh"}>
      <Container maxWidth="xs" className='text-center'>
        {
          (isLoading || loading) && <Loader />
        }
        <img src={process.env.PUBLIC_URL + 'images/splash.png'} alt="splash" width={250} />
        {
          isValid ? <Button fullWidth variant='contained' startIcon={<Google />} onClick={signin}>
            Sign In with Google
          </Button> :
            <>
              <TextField
                variant='outlined'
                fullWidth
                value={email}
                type="email"
                label="Email"
                margin='dense'
                size='small'
                placeholder="Enter Email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
              <br />
              <br />
              <Button
                fullWidth
                onClick={() => handleCheck()}
                variant="contained"
                color="primary"
                disabled={!validEmail(email)}
                startIcon={<Lock />}>
                Login
              </Button>
            </>
        }
      </Container>
    </Box>
  );
}

export default Login;
