import { action } from "typesafe-actions";
import { PresaleActionTypes } from "./presale.model";

export const fetchPresaleRequest = (req) => action(PresaleActionTypes.FETCH_PRESALE_REQUEST, req);
export const fetchPresaleSuccess = (res) => action(PresaleActionTypes.FETCH_PRESALE_SUCCESS, res);
export const fetchPresaleFailure = (err) => action(PresaleActionTypes.FETCH_PRESALE_FAILURE, err);

export const updatePresaleRequest = (req) => action(PresaleActionTypes.UPDATE_PRESALE_REQUEST, req);
export const updatePresaleSuccess = (res) => action(PresaleActionTypes.UPDATE_PRESALE_SUCCESS, res);
export const updatePresaleFailure = (err) => action(PresaleActionTypes.UPDATE_PRESALE_FAILURE, err);
