import { PresaleActionTypes, presaleInitialState } from "./presale.model";

const reducer = (state = presaleInitialState, action) => {
  switch (action.type) {
    case PresaleActionTypes.FETCH_PRESALE_REQUEST:
    case PresaleActionTypes.UPDATE_PRESALE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case PresaleActionTypes.FETCH_PRESALE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        presales: action.payload,
      };

    case PresaleActionTypes.UPDATE_PRESALE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        presales: state.presales.map((t) => {
          if (t.id === action.payload.id) {
            t = action.payload;
          }
          return t;
        }),
      };

    case PresaleActionTypes.FETCH_PRESALE_FAILURE:
    case PresaleActionTypes.UPDATE_PRESALE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export { reducer as PresaleReducer };

