export const PresaleActionTypes = {
    FETCH_PRESALE_REQUEST: '@@transaction/FETCH_PRESALE_REQUEST',
    FETCH_PRESALE_SUCCESS: '@@transaction/FETCH_PRESALE_SUCCESS',
    FETCH_PRESALE_FAILURE: '@@transaction/FETCH_PRESALE_FAILURE',

    UPDATE_PRESALE_REQUEST: '@@transaction/UPDATE_PRESALE_REQUEST',
    UPDATE_PRESALE_SUCCESS: '@@transaction/UPDATE_PRESALE_SUCCESS',
    UPDATE_PRESALE_FAILURE: '@@transaction/UPDATE_PRESALE_FAILURE',
}

export const presaleInitialState = {
    presales: [],
    isLoading: false
}