import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { AuthReducer } from "./auth/auth.reducer";
import { DepositReducer } from "./deposit/deposit.reducer";
import { PresaleReducer } from "./presale/presale.reducer";
import { SupportReducer } from "./support/support.reducer";
import { TransactionReducer } from "./transaction/transaction.reducer";
import { UserActionTypes } from "./user/user.model";
import { UserReducer } from "./user/user.reducer";
import { WhitelistReducer } from "./whitelist/whitelist.reducer";
import { WithdrawReducer } from "./withdraw/withdraw.reducer";

const appReducer = combineReducers({
  UserReducer,
  AuthReducer,
  DepositReducer,
  WithdrawReducer,
  TransactionReducer,
  WhitelistReducer,
  SupportReducer,
  PresaleReducer,
});

const persistConfig = {
  key: "bitazure",
  storage: storageSession,
};
const rootReducer = (state, action) => {
  if (action.type === UserActionTypes.USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
